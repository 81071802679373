import { isAuthenticated } from "../commons/Authenticator";
import {
  LOGIN_REQUESTED,
  LOGIN_RESPONSE,
  LOGOUT_REQUESTED,
  AUTHENTICATION_FAILED,
} from "../actions/LoginAction";

const LoginReducer = (
  state = {
    isLoggedIn: isAuthenticated(),
    isAuthencationFailed: false,
    isLoggingIn: false,
    isLoggingOut: false
  },
  action
) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
      return {
        ...state,
        data: action.login,
        isAuthencationFailed: false,
        isLoggingIn: true,
        isLoggedIn: false,
      };
    case LOGIN_RESPONSE:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        isAuthencationFailed: false,
        data: action.login,
      };
    case AUTHENTICATION_FAILED:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        isAuthencationFailed: true,
        data: undefined,
      };
    case LOGOUT_REQUESTED:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        isAuthencationFailed: false,
        data: undefined,
        isLoggingOut: true
      };
  }
  return state;
};

export default LoginReducer;
