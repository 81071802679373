import axios from "axios";
import cookie from "react-cookie";
import { delay } from "redux-saga";
import { call } from "redux-saga/effects";
import { isAuthenticated } from "../commons/Authenticator";
import { ERROR_MESSAGE, ANSWERING_MEDIUMS, ANSWERING_MEDIUMS_HEADER } from "../commons/Constants";
import {
  getApiVersion, getIsAWRecentComponent,
  isInternalServerError} from "../commons/Utility";
import ExceptionReducer from "../reducers/ExceptionReducer";
import { browserHistory } from "react-router";
import { store } from "../app";
import { destroyRtmServer } from "../actions/MessageAction";
import { logout } from "../actions/LoginAction";

/* 
if( getAppMode() == "live" )
{
	axios.defaults.baseURL = 'https://app.chatsupport.co/api';
	axios.defaults.withCredentials = true;	
}
else
{ 
	axios.defaults.withCredentials = true;
	axios.defaults.baseURL = 'https://staging.chatsupport.co/api';
} */
axios.defaults.withCredentials = true;
axios.defaults.baseURL = getApiVersion();
// axios.defaults.baseURL =
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common["x-origin"] = document.location.origin;

axios.interceptors.response.use(
  response => response,
  error => {
    const { response, config } = error;
    const isUnauthorized = response?.status === 401;
    const isUserEndpoint = config?.url?.includes("/user");
    const isLoggingOut = store.getState()?.LoginReducer?.isLoggingOut;

    if (isUnauthorized && !isUserEndpoint && !isLoggingOut) {
      console.warn("Session expired or invalid token. Logging out...");
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

function AuthenticationException(message) {
  this.message = message;
  this.name = "UserAuthenticationFailed";
}
function BadResponseException(message) {
  this.message = message;
  this.name = "Bad Response";
}

export const getAxiosError = (error) => {
  return error.response ? error.response : error;
};
export const get = (url, params, type, headers) => {
  if (isAuthenticated()) {
    //either pass the 'config' as another param OR set config defaults
    //return axios( url, config  ).then( response => response.data).catch( error => {throw error} );
    return axios
      .get(url, { headers: getHeaders() })
      .then((response) => {
        console.log("Hurray got the response normal 1",JSON.stringify(response));
        return response.data
      })
      .catch((error) => {
        console.log("Hurray got the response normal error 1", error);
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const getHeaders = () => {
  let headers = {};
  if (getIsAWRecentComponent()) {
    headers[ANSWERING_MEDIUMS_HEADER] = ANSWERING_MEDIUMS.ANYWHERE;
  } else {
    headers[ANSWERING_MEDIUMS_HEADER] = ANSWERING_MEDIUMS.DASHBOARD; 
  }
  return headers;
}

export const deleteReq = (url, params, type) => {
  if (isAuthenticated()) {
    return axios
      .delete(url, { headers: getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const deleteUnauthenticated = (url) => {
  return axios
    .delete(url, { headers: getHeaders() })
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const post = (url, jsonPayload) => {
  if (isAuthenticated()) {
    //either pass the 'config' as another param OR set config defaults
    //return axios( url, config  ).then( response => response.data).catch( error => {return error} );
    console.log("inside axios post!", jsonPayload);
    return axios
      .post(url, jsonPayload, { headers: getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const postWithToken = (url, jsonPayload, token) => {
  if (isAuthenticated()) {
    return axios
      .post(url, jsonPayload,{cancelToken: token, headers: getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        if(!isRequestCanceled(error) && !isInternalServerError(error.response)){
          browserHistory.push('/admin');
        }
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};
export const postFormData = (url, formData) => {
  if (isAuthenticated()) {
    //either pass the 'config' as another param OR set config defaults
    //return axios( url, config  ).then( response => response.data).catch( error => {throw error} );
    console.log("inside axios postFormData!", formData);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios
      .post(url, formData, config)
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const postDataToFullStorageUnauthenticated = (url, formData) => {
  delete axios.defaults.headers.common["x-origin"];
  console.log("Accept", axios.defaults.headers.common);
  console.log("Accept", axios.defaults.headers.common["x-origin"]);
  delete axios.defaults.headers.common["Accept"];
  const config = {
    crossDomain: true,
    withCredentials: false,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  let response = axios
    .post(url, formData, config)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
  axios.defaults.headers.common["x-origin"] = document.location.origin;
  axios.defaults.headers.common["Accept"] = "application/json, text/plain, */*";
  return response;
};

export const postDataToFullStorage = (url, formData, customConfig) => {
  if (isAuthenticated()) {
    delete axios.defaults.headers.common["x-origin"];
    console.log("Accept", axios.defaults.headers.common);
    console.log("Accept", axios.defaults.headers.common["x-origin"]);
    delete axios.defaults.headers.common["Accept"];
    let config = {
      crossDomain: true,
      withCredentials: false,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (customConfig) config = { ...config, ...customConfig };
    let response = axios
      .post(url, formData, config)
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
    axios.defaults.headers.common["x-origin"] = document.location.origin;
    axios.defaults.headers.common["Accept"] =
      "application/json, text/plain, */*";
    return response;
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const put = (url, jsonPayload) => {
  if (isAuthenticated()) {
    console.log("inside axios Put!", jsonPayload);
    return axios
      .put(url, jsonPayload, { headers: getHeaders() })
      .then((response) => response.data)
      .catch((error) => {
        return error;
      });
  } else {
    throw new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
  }
};

export const login = (url, paramObj) => {
  console.log("Login XHR paramObj -->> ", paramObj);

  return axios
    .post(url, paramObj)
    .then((response) => response)
    .catch((error) => {
      return new AuthenticationException(ERROR_MESSAGE.AUTHENTICATION);
    });
};

export const signUp = (url, paramObj) => {
  console.log("SignUpData->", paramObj);

  return axios
    .post(url, paramObj)
    .then((response) => response)
    .catch((error) => {
      return getAxiosError(error);
    });
};

export const createCancelToken = () => {
  return axios.CancelToken.source();
}

export const cancelPreviousRequest = (cancelToken, message) => {
  if (cancelToken && cancelToken.cancel) {
    cancelToken.cancel(message);
  }
}

export const isRequestCanceled = (error) => {
  return axios.isCancel(error);
}

export const postUnauthenticated = (url, paramObj, callback) => {
  return axios
    .post(url, paramObj, { headers: getHeaders() })
    .then((response) => response.data)
    .catch((error) => {
      return getAxiosError(error);
    });
};
export const getUnauthenticated = (url) => {
  return axios
    .get(url)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};

export const putUnauthenticated = (url, jsonPayload) => {
  return axios
    .put(url, jsonPayload)
    .then((response) => response.data)
    .catch((error) => {
      return error;
    });
};
export const postToDifferentBaseUrl = (baseUrl, url, paramObj, token) => {
  delete axios.defaults.headers.common["x-origin"];
  return axios
    .post(url, paramObj, {
      baseURL: baseUrl,
      headers: { Authorization: token },
      withCredentials: false,
    })
    .then((response) => response.data)
    .catch((error) => {
      return getAxiosError(error);
    })
    .finally(() => {
      axios.defaults.headers.common["x-origin"] = document.location.origin;
    });
};

export const putToDifferentBaseUrl = (baseURL, url, paramObj, token) => {
  return axios
    .put(url, paramObj, { baseURL: baseURL, headers: { Authorization: token } })
    .then((response) => response.data)
    .catch((error) => {
      return getAxiosError(error);
    });
};

export const getFromDifferentBaseUrl = (baseUrl, url, token) => {
  delete axios.defaults.headers.common["x-origin"];
  let returnedPromise = axios
    .get(url, {
      baseURL: baseUrl,
      withCredentials: false,
      headers: { Authorization: token },
    })
    .then((response) => response.data)
    .catch((error) => {
      return getAxiosError(error);
    });
  axios.defaults.headers.common["x-origin"] = document.location.origin;
  return returnedPromise;
};

export const callWithRetry = function* callWithRetry() {
  let responseValidator = arguments[0];
  let functionToCall = arguments[1];
  let args = [].slice.call(arguments, 2);
  for (let i = 0; i < 3; i++) {
    try {
      let response = yield call(functionToCall, ...args);
      if (responseValidator(response)) return response;
      else {
        if (i < 2) {
          yield call(delay, 2000);
        } else {
          throw new BadResponseException(
            "Bad response: " + JSON.stringify(response)
          );
        }
      }
    } catch (e) {
      console.error("error occurred with retry :", JSON.stringify(e), JSON.stringify(args));
      if (i < 2) {
        console.error("Retrying!");
        yield call(delay, 2000);
      } else {
        throw e;
      }
    }
  }
};

export const getCallWithRetry = function* getCallWithRetry() {
  let responseValidator = arguments[0];
  let functionToCall = arguments[1];
  let url = arguments[2];
  let delaySeconds = arguments[3];
  for (let i = 0; i < 3; i++) {
    try {
      let response = yield call(functionToCall, url);
      console.log("Hurray got the response retry 1",JSON.stringify(response));
      if (responseValidator(response)) return response;
      else {
        console.log("Hurray got the response retry 2",JSON.stringify(response));
        if (i < 2) {
          yield call(delay, delaySeconds);
        } else {
          throw new BadResponseException(
            "Bad response: " + JSON.stringify(response)
          );
        }
      }
    } catch (e) {
      console.error(e);
      console.log("Hurray got the response retry catch 1", e);
      if (i < 2) {
        console.error("Retrying!");
        yield call(delay, delaySeconds);
      } else {
        throw e;
      }
    }
  }
};
